import React, { useState, useEffect } from 'react';
import Background from '../Assets/code1.jpg';
import Background2 from '../Assets/kevin-ku-aiyBwbrWWlo-unsplash.jpg';
import Code from '../Assets/code2.jpg';
import Typical from 'react-typical';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactImg from '../Assets/logo192.png';
import { Link as ScrollLink } from 'react-scroll';

const Header = () => {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  // linear-gradient(to right, rgb(91, 121, 162) 0%, rgb(46, 68, 105) 100%)
  return (
    <div
      className='z-10 header-hero'
      id='Home'
    >
      <div className='min-h-screen flex'>
        <div className='w-10/12 mx-auto flex flex-col md:flex-row-reverse  justify-center md:justify-between items-center space-y-5'>
          <div className='md:w-2/5 w-3/5 flex flex-col'>
            {' '}
            <LazyLoadImage
              className='rounded-3xl hidden lg:block shadow-2xl transform w-3/5 rotate-45'
              src={Background}
              effect='blur'
              alt='background'
              delayTime={1000}
              placeholderSrc={ReactImg}
            />
            <LazyLoadImage
              className='rounded-3xl hidden lg:block shadow-2xl transform w-3/5 rotate-45'
              src={Code}
              effect='effect'
              alt='background'
              delayTime={500}
              placeholderSrc={ReactImg}
            />
            <img
              className='lg:hidden rounded-2xl shadow-2xl'
              src={Background2}
              alt='codes'
            />
          </div>

          <div className='text-center md:text-justify'>
            <h1
              className={`${
                animated ? '' : 'translate-y-20 opacity-0'
              } transform transition duration-1000 ease-in-out font-light text-2xl  text-gray-600 lg:text-4xl md:text-3xl`}
            >
              Hi,
              <br />
              Meet Wealth
            </h1>
            {/* <p>
            I Write Clean, Resusable, Readable and
            <br /> Scalable Frontend Code.
          </p> */}
            <p
              className={`${
                animated ? '' : 'translate-y-10 opacity-0'
              } transform transition duration-1000 mt-2 text-gray-600 font-Caudex text-lg ease-in-out`}
            >
              I'm proficient with{' '}
              <Typical
                className={'text-gray-600 font-Caudex'}
                loop={Infinity}
                wrapper='span'
                steps={[
                  'Semantic HTML💻',
                  1000,
                  'Tailwind CSS💻',
                  1000,
                    'Bootstrap💻',
                    1000,
                  'Javascript💻',
                  1000,
                    'Typescript💻',
                    1000,
                  'React💻',
                  1000,
                    'Angular💻',
                    1000,
                  'Redux💻',
                  1000,
                  'Git',
                  1000,
                    'Docker',
                    1000,
                ]}
              />
            </p>

           

            {/* <p
            className={`${
              animated ? "" : "translate-y-10 opacity-0"
            } transform transition duration-1000  text-2xl ease-in-out`}
          >
            Loving React Daily, React For Show😎
          </p> */}

            <ScrollLink to='About' smooth={true}>
              <button className='animate-float transition duration-1000 bg-gray-700 text-wheat  px-8 py-4 rounded-full rounded-tl-3xl uppercase mt-8 hover:bg-gray-500 shadow-lg'>
                Discover Me...
              </button>
            </ScrollLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;