import React from "react";

const Jobs = () => {
  return (
    <div id='Jobs' className="text-md mt-5 text-justify">
      <section className="flex flex-col border-b  pb-3 justify-between md:flex-row">
        <article className="flex md:flex-col">
          <p>02/2022 - </p>
          <p>06/2023</p>
        </article>
        <div className="sm:max-w-lg lg:w-10/12 lg:max-w-2xl text-md">
          <p className=" font-medium">Remita Payment Service Limited</p>
          <p>Frontend Developer</p>
          <p className='font-medium'>Technologies used: Angular, React, Jquery, Javascript, Typescript, JSP,  NGRX, JWT, Bootstrap-4</p>
          <span className="italic">
                Developed an internal solution (Admin Console) with roles and
                permission features that drastically reduced the processing time
                of client requests, even over the weekend by 80%. It modifies
                records on the database using dynamic forms and stored
                procedures that executes SQL queries</span>
          <ul className="list-circle">
          <li>
            Implemented responsive pixel perfect figma designs, fit for all device
            screen breakpoints (mobile, tablets, and desktop).
          </li>

          <li>
            Integrated APIs on our frontend Angular and javascript applications using
            JSON Web Tokens (JWT) and cookies.
          </li>

          <li>Built more reusable components (modals, tables, clipboard, inputs, password-validators) across our angular frontend for easy debugging and testing. </li>
          <li>Maintained two legacy projects (payment gateway and payment and collection web-application) by debugging, refactoring and implementing new features.</li>

          <li>Implemented i18 language translation feature and geospatial/geo-location map representation on our angular frontend.</li>

          <li>Completed all assigned tickets timely at the company's project live hackathon 3weeks coding camp. </li>
          <li>Migrated UI elements into newly built reusable components, and migrated projects into mono-repo architecture using Nx.</li>
          <li>Implemented web accessibility principles with accordance to WCAG 2.1 across web applications.</li>
          <li>
            Delivered three practical live coding demo for frontend team, and management to update them on projects status, and newer technologies.
          </li>
          <li>
            Training interns on fundamentals and best practices of frontend
            technologies; and assisting them in reviewing & debugging their
            solutions.
          </li>
          </ul>
        </div>
      </section>
      <section className="flex flex-col border-b pb-3 justify-between md:flex-row mt-5">
        <article className="flex md:flex-col ">
          <p>12/2021-</p>
          <p> 01/2022</p>
        </article>
        <div className="sm:max-w-lg lg:w-10/12 lg:max-w-2xl text-md">
          <p className=" font-medium">Zuri Chat</p>
          <p> Frontend Developer</p>
          <p className='font-medium'>Technologies used: React, Angular, Typescript, Javascript, Redux, JWT, Bootstrap, Tailwind</p>
        <ul className="list-circle">
          <li>
            Implementing pixel perfect figma designs, fit for all device
            screen breakpoints.
          </li>
          <li>Implemented app-wide state management using  redux with local storage sync and contextAPI.</li>

          <li> Integrated APIs on our frontend React application using
            JSON Web Tokens(JWT).</li>

          <li>
            Make PRs on approved tickets and resolving conflicts locally and
            on the hub using GIT.
          </li>

          <li>
            Collaboration with UI/UX designers, backend, and other frontend
            developers on group tasks using gitHub, zoom, google meet to
            deliberate on tasks remotely.
          </li>

          <li>
            Collaboration with UI/UX designers, backend, and other frontend devs
            on group tasks using gitHub, zoom, google meet to deliberate on tasks
            remotely.
          </li>
          </ul>
        </div>
      </section>
      <section className="flex mt-5 flex-col pb-2 justify-between md:flex-row">
        <article className="flex md:flex-col ">
          <p>08/2020 - </p>
          <p>01/2021 </p>
        </article>
        <div className="sm:max-w-lg lg:w-10/12 lg:max-w-2xl text-md">
          <p className=" font-medium"> Auntie Network </p>
          <p>Frontend Developer</p>
          <p className='font-medium'>Technologies used: React, HTML, CSS, Javascript, Redux, Bootstrap, Jquery</p>
          <ul className="list-circle">
          <li>
            Completed frontend tasks; implementing pixel perfect designs,
            consuming rest APIs, improving user experience on all devices
            through mobile responsiveness.
          </li>
          <li>Worked on the chat component and integrated APIs using web-socket for real-time messaging.
          </li>
          <li>Monthly career seminars on tech-related subjects.</li>
          <li>
            Collaboration with other engineers on group tasks using gitHub,
            zoom, meet to discuss tasks and available tickets.
          </li>
          </ul>
        </div>
      </section>
      {/*<section className="mt-5 flex flex-col justify-between md:flex-row ">*/}
      {/*  <article className="flex md:flex-col ">*/}
      {/*    <p>05/03/2021 - </p>*/}
      {/*    <p>25/05/2021</p>*/}
      {/*  </article>*/}
      {/*  <div className="sm:max-w-lg lg:w-10/12 lg:max-w-2xl text-md">*/}
      {/*    <p className=" font-medium">Ustacky Microdegree Scholarship</p>*/}
      {/*    <p>Ustacky Internship - Frontend Developer</p>*/}
      {/*    <p>*/}
      {/*      Practical modules on frontend Development basics (HTML, CSS,*/}
      {/*      javascript).*/}
      {/*    </p>*/}
      {/*    <p>Practical task after each module.</p>*/}
      {/*    <p>One-time Career seminars about the Tech Industry.</p>*/}
      {/*    <p>*/}
      {/*      My performance got me an opportunity for a professional project*/}
      {/*      review and a certificate.*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</section>*/}
    </div>
  );
};

export default Jobs;
