import React from 'react';
import Thumbnail from '../Assets/portfolio.png';
import { FaTwitter, FaFacebook, FaLinkedin, FaGithub } from 'react-icons/fa';

const ContactContent = () => {
  return (
    <div className='md:block flex-1 shadow-2xl mx-auto transform rounded-2xl overflow-hidden'>
      <img src={Thumbnail} alt={'Thumbnail'} />
      <div className='text-center'>
        <div className=' flex justify-center justify-evenly'>
          <a href='https://github.com/mrWealthid/'>
            <span className='mx-3'>
              <FaGithub color='black' />
            </span>
          </a>

          <a href='/'>
            <span className='mx-3'>
              <FaLinkedin color='#0077b5' />
            </span>
          </a>

          <a href='/'>
            <span className='mx-3'>
              <FaTwitter color='#1DA1F2' />
            </span>
          </a>

          <a href='/'>
            <span className='mx-3'>
              <FaFacebook color='#4267B2' />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactContent;
