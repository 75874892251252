import React from "react";

import Img from "../Assets/Mr_Wealth.jpeg";
import {FaDownload} from "react-icons/fa";

import Resume from "./Wealth-Resume.pdf";
import Fade from "react-reveal/Fade";

const About = () => {
    return (
        <div
            className="bg-wheat flex mt-10 lg:mt-6 lg:min-h-screen justify-center items-center"
            id="About"
        >
            <Fade bottom opposite>
                <div className="flex flex-col md:flex-row w-10/12 justify-between space-y-5 lg:space-y-0 items-center">
                    <div>
                        <img
                            className="w-40 h-40 object-cover  rounded-full  shadow-2xl"
                            src={Img}
                            alt="wealth"
                        />
                    </div>

                    <div className="space-y-5">
                        <p className="mx-auto max-w-2xl lg:mx-0 md:max-w-lg lg:max-w-2xl  text-md text-justify ">

                           <span>  An experienced software engineer with +3 years of experience in frontend development (Angular & React), and less than a year in backend development(Node).
                               With a keen eyes for details, Wealth excels in implementing pixel-perfect UI designs while ensuring optimal user experience through the use of readable, reusable, and scalable code. Wealth's expertise lies in frontend frameworks such as React and Angular, employing functional
                            programming with hooks and object-oriented programming respectively.
                               </span>
                            <br/>
                            <br/>
                            <span>  Proficient in various aspects of web development, Wealth understands
                            DOM manipulation, event handling, unit testing, end-to-end testing, code versioning, closures, pure functions, asynchronous programming, backend development,testing, API integration,  error
                            handling, web content accessibility and many more. This knowledge enables wealth build reusable components, maintainable and accessible web applications for all groups.
                           </span>
                            <br/>
                            <br/>
                            <span>  Worked as a software engineer at Remita Payment Service Limited, a subsidiary of
                            systemspecs group, Wealth has played a crucial role in the development of the internal
                            solution called Admin console, with over
        80% slash in processing time of business request. Additionally, Wealth has contributed to the creation of RemitaAgent, an
                            enterprise fintech application, from the ground up using Angular 14. Wealth's commitment to continuous
                            learning, paired with a strong work ethic, has earned them accolades during bi-annual
                            appraisal cycles.
                              </span>
                            <br/>
                            <br/>
                            With a comprehensive skill set encompassing;
                            <br/>

                            <span className='font-bold'> JavaScript, TypeScript, Angular, React,
                            Next, Ngrx, Redux, Jasmine, Cypress, CSS, SCSS, JWT, jQuery, HTML, Tailwind, Bootstrap, Docker, REST API, Node,
                            Express, Mongoose, MongoDB, and Git.</span>

                            <span> Wealth is well-equipped to tackle a wide range of frontend
                            development challenges. Passionate about innovative implementations and captivating designs,
                            Wealth consistently strives to deliver exceptional results.</span>


                            {/*Hi there, I'm Wealth! A frontend engineer with 3years of professional experience*/}
                            {/*in developing interactive and responsive user interfaces for best user experience.*/}
                            {/*<br/>*/}
                            {/*I write readable, reusable, scalable, declarative codes with*/}
                            {/*strict compliance to best practices and understand every stage of*/}
                            {/*project's cycle from design, development and deployment. With my*/}
                            {/*vast knowledge of CSS I can implement pixel-perfect UI designs*/}
                            {/*with great deal of responsiveness.*/}
                            {/*<br/>*/}
                            {/*Proficient with react using functional programming with hooks and angular using Object*/}
                            {/*oriented programming.*/}
                            {/*Well-versed with DOM manipulation, DOM traversing, event delegation in target, capture and*/}
                            {/*bubbling phases, pure functions,*/}
                            {/*web APIs, async programming, consuming promises using promise combinators and error*/}
                            {/*handling.*/}
                            {/*<br/>*/}

                            {/*Presently work with Remita Payment Service Limited as a software engineer; A subsidiary of*/}
                            {/*systemspecs group, where I built internal solution called Admin console with over 80% slash*/}
                            {/*in processing time of several business requests categorized into admin functions, maintain*/}
                            {/*legacy projects and was key part of the team that developed from ground up an enterprise*/}
                            {/*fintech application called RemitaAgent (Agency Banking).*/}
                            {/*I have earned great commendations at bi-annual appraisal cycles.*/}
                            {/*<br/>*/}

                            {/*Wealth is an ardent learner, teachable, hard-working, with great passion for amazing designs*/}
                            {/*and innovative implementations. <br/>*/}
                            {/*My Competencies: JavaScript, Typescript, Angular, Ngrx, React, Next, Redux, CSS, SCSS, JWT,*/}
                            {/*Jquery, HTML, Tailwind, Bootstrap, Docker, restAPI, Node, Express, MongoDB and GIT*/}
                        </p>

                        <a className="block" href={Resume}>
                            <button
                                className="py-4 mx-auto rounded-br-3xl lg:mx-0 px-4 space-x-4 flex rounded-full items-center bg-gray-700  transition duration-1000 hover:bg-gray-500"
                                style={{color: "wheat"}}
                            >
                <span className="mx-2">
                  <FaDownload/>
                </span>
                                Download Resume
                            </button>
                        </a>
                    </div>
                </div>
            </Fade>
        </div>
    );
};

export default About;
