import React from "react";
import Ustacky from "../Assets/Ecommerce.png";
import Dictionary from "../Assets/Dictionary.png";
import LandingPage from "../Assets/landingPage.png";
import Signup from "../Assets/signUp2.png";
import HNG2 from "../Assets/Hng2.png";
import Blog from "../Assets/myBlog.png";
import Shop from "../Assets/RShop.png";
import Banking from "../Assets/Bank.png";
import JobApp from "../Assets/jobapp.png";
import ChatApp from "../Assets/Chats.png";
import MeanApp from "../Assets/MeanApp.png";
import MernApp from "../Assets/Mern.png";

import Meal from "../Assets/Meal.png";
import Dashboard from "../Assets/dashboard.png";
import { FaAngleDoubleDown } from "react-icons/fa";
import Cards from "./Cards";
import Jobs from "./Jobs";
import Thumbnail from "../Assets/portfolio.png";
import Colorgen from "../Assets/colorgen.png";


const Projects = () => {
  return (
    <div className="w-10/12  lg:mt-0  mt-10 mx-auto" >
    
        <Jobs />
        <section id="Projects">
     
        <h1 className="text-xl flex items-center mt-10 justify-center md:text-2xl lg:text-3xl text-gray-600 font-bold ">
          Personal Projects
          <span className="mx-2 animate-ping text-sm">
            <FaAngleDoubleDown />
          </span>
        </h1>

        <div  className="grid cursor-pointer gap-y-6 gap-x-4 mt-10  grid-col-1 sm:grid-cols-2  md:grid-cols-2 justify-center lg:grid-cols-3">
         
        <Cards
            projectLink={"https://hotel-app-blush-beta.vercel.app"}
            alt={"Hotel Reservation App"}
            imgLink={MernApp}
            projectTitle={"MERN: Hotel Reservation App"}
            projectDesc={
              "Technologies: Next Js, Typescript,  Mongoose, MongoDB and Tailwind"
            }
          />
         
         
        <Cards
            projectLink={"https://bank-app.wealthtech.website"}
            alt={"Wallet App"}
            imgLink={MeanApp}
            projectTitle={"MEAN Stack Wallet Application"}
            projectDesc={
              "Technologies: Angular, Ngrx, Typescript, Node, Express, Mongoose, MongoDB and Tailwind"
            }
          />
         
          <Cards
            projectLink={"https://bankapp-react.netlify.app"}
            alt={"Banking App"}
            imgLink={Banking}
            projectTitle={"Banking App"}
            projectDesc={
              "Technologies: React, Redux, Firebase, Routing and Code spliting, Real Time Transactions & Tailwind  CSS"
            }
          />


            <Cards
                projectLink={"https://react-type-dashboard.netlify.app"}
                alt={"React Admin Dashboard"}
                imgLink={Dashboard}
                projectTitle={"React Admin Dashboard"}
                projectDesc={
                    "Technologies: React, Typescript, Tailwind, Apex-Charts"
                }
            />
          <Cards
            projectLink={"https://wealthblog.netlify.app"}
            alt={"react-blog"}
            imgLink={Blog}
            projectTitle={"A React Blog"}
            projectDesc={
              "Technologies: React Js, Tailwind CSS, Redux, restApi, Authentication, Protected Routes, replies & like features etc"
            }
          />

          <Cards
            projectLink={"https://recipemeals.netlify.app"}
            alt={"Recipe"}
            imgLink={Meal}
            projectTitle={"A React Recipe Site "}
            projectDesc={
              "Technologies: React Js, Tailwind, Axios for API request(s), Promises Combinators, Event delegation, router etc"
            }
          />

          <Cards
            projectLink={"https://tedbreejobapp.netlify.app"}
            alt={"Job App"}
            imgLink={JobApp}
            projectTitle={"Job App"}
            projectDesc={
              "Technologies: React Js, Context, modals, Tailwind  CSS, Routing and Code spliting"
            }
          />

          <Cards
            projectLink={"https://wealthchats.netlify.app"}
            alt={"Chat App"}
            imgLink={ChatApp}
            projectTitle={"Chat App"}
            projectDesc={
              "Technologies: React Js, Firebase Cloud Storage, Firebase Auth, Firestore  & Tailwind  CSS"
            }
          />

          <Cards
            projectLink={"https://ustackyecommerce.netlify.app"}
            alt={"ustacky"}
            imgLink={Ustacky}
            projectTitle={"An E-commerce Website"}
            projectDesc={
              "Technologies: HTML, CSS & Javacript; Functional programing, Integrated with Paystack for Payment"
            }
          />

          <Cards
            projectLink={"https://redux-ecommerceshop.netlify.app"}
            alt={"ustacky"}
            imgLink={Shop}
            projectTitle={"A React E-commerce Website"}
            projectDesc={
              "Technologies: Reactjs, Redux, and Local Storage to Persist Data"
            }
          />

          <Cards
            projectLink={"https://react-dict.netlify.app"}
            alt={"Dictionary"}
            imgLink={Dictionary}
            projectTitle={"A Dictionary App"}
            projectDesc={
              " Built with Audio Support and Dark-Mode Toggle; using ReactJs, axios, Google Api, Tailwind CSS"
            }
          />

          <Cards
            projectLink={"https://hngprojectsite.netlify.app"}
            alt={"HNG2"}
            imgLink={HNG2}
            projectTitle={"A Complete Website"}
            projectDesc={
              " Implemented from a Figma Design using HTML, CSS & Javacript(Fully Responsive)"
            }
          />

          <Cards
            projectLink={"https://wealthportfolio.netlify.app"}
            alt={"portfolio"}
            imgLink={Thumbnail}
            projectTitle={"A Portfolio Website"}
            projectDesc={
              " Built with Reactjs, Tailwind CSS- Responsive on all devices"
            }
          />

          <Cards
            projectLink={"https://wealthreact-projects.netlify.app/"}
            alt={"projects"}
            imgLink={Colorgen}
            projectTitle={"React Workflow Logics"}
            projectDesc={
              "16 react projects using React hooks, useReducer, useContext, useParams,CRUD, API Integrations etc.."
            }
          />

          <Cards
            projectLink={"https://sitetests.netlify.app/"}
            alt={"projects"}
            imgLink={LandingPage}
            projectTitle={"Landing Page"}
            projectDesc={
              "Implemented Figma Design- A Responsive Landing Page split into components"
            }
          />

          <Cards
            projectLink={"https://company-task.netlify.app/question3"}
            alt={"projects"}
            imgLink={Signup}
            projectTitle={"An Advanced Signup Form"}
            projectDesc={"Implemented Figma Design- A Responsive Signup Form"}
          />
        </div>

  
      </section>
    </div>
  );
};

export default Projects;
